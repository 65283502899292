<template>
    <div class="aplayz_modal">
        <div class="prairle_modal_wrap">
            <h1 class="prairle_title">쿠폰 사용 방법</h1>
            <ul class="flex_column prairle_list">
                <li class="prairle_item">1. 코드 복사하기</li>
                <li class="prairle_item">2. 앱 다운로드 하기</li>
                <li class="prairle_item app_desc">
                    3. 앱 내 우측 상단 아이콘 &nbsp;
                    <img class="menu_icon" src="/media/img/hanwhaprairie/menu.png" alt="메뉴" /> &nbsp;클릭
                </li>
                <li class="prairle_item">4. ‘쿠폰’ 클릭 후 쿠폰코드 등록 및 사용</li>
            </ul>
            <button class="confirm_btn" @click="$emit('close-couponguide')">확인</button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'CouponGuide',
  emits: ['close-couponguide'],
  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/hanwhaprairie/hanwhafont.css"></style>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped>
.prairle_modal_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 283px;
  border-radius: 12px;
  background: #404040;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
  padding: 20px;
}

.prairle_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  color: var(--white);
}

.prairle_list {
  margin-top: 9px;
}

.prairle_item {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: var(--white);
}

.prairle_item.app_desc {
  display: flex;
  align-items: center;
}

.menu_icon {
  display: inline-block;
  width: 11px;
  height: 7px;
  margin-top: 3px;
}

.confirm_btn {
  font-family: 'Noto Sans KR', sans-serif;
  background-color: var(--FF2D55);
  color: var(--white);
  border-radius: 6px;
  width: 100%;
  padding: 8px 0;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  margin-top: 16px;
}
</style>
